import React, {Component} from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import {Auth, Credentials} from 'common/logic/Auth';

class LoginForm extends Component {

    constructor(props) {

        super(props);
        this.state = {
            username: null,
            pasword: null,
            isChecking: false,
            error: null
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        const {loginCallback} = props
        this.loginCallback = loginCallback
    }
    
    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        this.setState({[target.name]: value});
    }

    handleSubmit(event) {
        event.preventDefault();
        if (!this.state.username || !this.state.password || this.state.isChecking) {
            return
        }
        this.setState({isChecking: true})
        const form = this
        const credentials = new Credentials(this.state.username, this.state.password)
        Auth.getCredentials()
        Auth.validateCredentials(credentials).then(function (response) {
            if(response) {
                form.setState({isChecking: false})
                Auth.setCredentials(credentials)
                form.loginCallback(form.state.username)
            }
        }).catch(function (error) {
            form.setState({isChecking: false, error: error})
        })
    }

    render() {
    
        return (
            <Form onSubmit={this.handleSubmit}>
            <Form.Group controlId="formEmail">
            <Form.Label>Username</Form.Label>
            <Form.Control 
                type="text" 
                name="username" 
                placeholder="Username" 
                onChange={this.handleInputChange} 
                disabled={this.state.isChecking}
                />
            </Form.Group>
        
            <Form.Group controlId="formPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control 
                type="password" 
                name="password" 
                placeholder="Password" 
                onChange={this.handleInputChange}
                disabled={this.state.isChecking}
                />
            </Form.Group>
            <Button variant="primary" type="submit" disabled={this.state.isChecking}>
            {this.state.isChecking ? <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                /> :
            <span>Submit</span>}
            </Button>
        </Form>
        )
    }
}

export default LoginForm
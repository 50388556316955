import React, {Component} from 'react'
import {ColumnDefinition} from 'common/components/DataTable';
import GenericList from 'common/components/GenericList';
import { Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import EditVariableWorkflow from 'application/variables/EditVariableWorkflow';
import OperationWorkflowModal from 'common/components/OperationWorkflowModal';
import API from 'common/logic/API';

class Settings extends Component {

    constructor(props) {
        
        super(props)
        this.state = {
            modal: false
        }
        
        this.showCreateDialog = this.showCreateDialog.bind(this)
        this.showDeletionDialog = this.showDeletionDialog.bind(this)
        this.hideDialogs = this.hideDialogs.bind(this)
    }

    render() {
        
        let modal = null
        if (this.state.modal) {
            switch(this.state.modal.type) {
                case "create":
                case "edit":
                    modal = this.creationOrEditModal(this.state.modal)
                    break;
                case "delete": 
                    modal = this.deletionModal(this.state.modal);
                    break;
                default:
            }
        }

        const definitions = [
            new ColumnDefinition("Name", "name"),
            new ColumnDefinition("Value", "value"),
            new ColumnDefinition("Actions", obj => { 
                return <>
                <Button variant="link" title="Edit"
                    onClick={this.showEditDialog.bind(this, obj.name)}><Icon.Pencil /></Button> 
                <Button variant="link" title="Delete"
                    onClick={this.showDeletionDialog.bind(this, obj.name)}><Icon.Trash /></Button>
                </>
            })
        ]

        return <>
            {modal}
            <GenericList 
            title="Variables" 
            columns={definitions} 
            subtitle={<><Button variant="link" onClick={this.showCreateDialog.bind(this, "string")}>Add string variable</Button>
            <Button variant="link" onClick={this.showCreateDialog.bind(this, "int")}>Add int variable</Button> 
            </>}
            dataKey={this.state.dataKey}
            endpoint="/variables" 
            />
        </>
    }

    showCreateDialog(type) {
        this.setState({
            modal: {
                type: "create",
                variableType: type
            }
        })
    }

    showDeletionDialog(name) {
        this.setState({modal: 
            {
                type: "delete",
                name: name,
            }
        })
    }

    showEditDialog(name) {
        this.setState({
            modal: {
                type: "edit",
                name: name,
                variableType: "string"
            }
        })
    }

    hideDialogs() {
        this.setState({modal: false})
    }

    refreshData() {
        this.setState({dataKey: Date.now()})
    }

    creationOrEditModal(params) {

        const completion = (result) => {
            this.hideDialogs()
            if(result) {
                this.refreshData()
            }
        }
        return <EditVariableWorkflow onSuccess={completion} variableName={params.name} variableType={params.variableType} />
    }

    deletionModal(params) {
        const confirmationStep = (p, l) => { return `Delete variable "${params.name}"?`} 
        const executeStep = (p, l, u) => {return API.delete(`/variables/${params.name}`) }

        const completion = (result) => {
            this.hideDialogs()
            if(result) {
                this.refreshData()
            }
        }

        return <OperationWorkflowModal
            confirmationStep={confirmationStep}
            executionStep={executeStep}
            completionCallback={completion}
        />
    }
}

export default Settings
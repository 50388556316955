import React, {Component} from 'react'
import { Alert, Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons'

/**
 * Props:
 * - filters: map of applied filters (name -> value)
 * - onUpdateFilters: callback(newFilters) when filters are modified
 */
class FilterBox extends Component {
    
    constructor(props) {
        super(props)
        this.removeFilters = this.removeFilters.bind(this)
    }

    removeFilters(keysToRemove) {
        let newFilters = {...this.props.filters}
        keysToRemove.forEach((e) => { delete newFilters[e]})
        if(this.props.onUpdateFilters) {
            this.props.onUpdateFilters(newFilters)
        }
    }
    
    render() {
        const filters = Object.entries(this.props.filters).filter((e, i) => {
            const [, value] = e
            return typeof value !== 'undefined' && value !== null
        })
        if (!filters || filters.length === 0) {
            return null
        }

        const filtersUI = filters.map(e => { 
            const [name, value] = e
            return <span className="filter-card" key={name}>
                        {name}: {value}
                        <Button
                            key={name}
                            variant="link"
                            onClick={this.removeFilters.bind(this, [name])}
                        ><Icon.XCircle /></Button>
                    </span>
        })

        return <Alert variant="info" className="filterbox">Filters: {filtersUI}</Alert>
    }
}

export default FilterBox
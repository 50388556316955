import React, {Component} from 'react'
import BigSpinner from 'common/components/BigSpinner';
import {DataTable} from 'common/components/DataTable';
import PaginationBox from 'common/components/PaginationBox'
import FetchBehavior from "common/logic/FetchBehavior"
import Title from "common/components/Title"

/**
 * Expected props:
 * - title: the title of this list
 * - query: the url query
 * - columns: an array of ColumnDefinitions
 * - endpoint: the API endpoint where the list can be fetched
 * - endpointParams: endpoint query parameters
 * - beforeTable: render this before the table and pagination
 * - afterTable: render this after the table and pagination
 * - dataKey: change this key to a new value (any value) to re-trigger a fetch of the data 
 */
class GenericList extends Component {

    constructor(props) {
        
        super(props)
        this.state = {
            results: null,
            limit: 50,
            sort: null
        }
    }

    componentDidUpdate(prevProps) {
        
        const sameParams = JSON.stringify(prevProps.endpointParams) === JSON.stringify(this.props.endpointParams)
        const sameEndpoint = prevProps.endpoint === this.props.endpoint
        const sameDataKey = prevProps.dataKey === this.props.dataKey
        if(!sameParams || !sameEndpoint || !sameDataKey) {
           this.startFetching()
        }
    }

    startFetching() {
        this.fetchBehavior = new FetchBehavior({
            endpoint: this.props.endpoint, 
            endpointParams: this.props.endpointParams, 
            stateHolder: this})
        this.fetchBehavior.newURLCallback = url => { 
            if(url !== window.location) {
                window.history.pushState(null, '', url) 
            }
        }

        this.fetchBehavior.fetchFromURL()
    }

    componentDidMount() {
        this.startFetching()
    }

    render() {
        const needsPagination = this.state.start > 0 || (this.state.results && this.state.results.hasMore)
        const paginationBox = needsPagination ? <PaginationBox 
            fetchCallback={this.fetchBehavior.fetchValues}
            start={this.state.results.start} 
            limit={this.state.limit} 
            sort={this.state.sort}
            hasMore={this.state.results.hasMore} /> : null

        return (
            <div>
                {this.props.title && <Title title={this.props.title} subtitle={this.props.subtitle}/>}
                {this.props.beforeTable}
                {paginationBox}
                {this.state.results && this.state.results.error && <p>Error!</p>}
                {!this.state.results || this.state.results.results === null ? <BigSpinner /> : 
                <DataTable columns={this.props.columns} 
                    rows={this.state.results.results}
                    />}
                {paginationBox}
                {this.props.afterTable}
            </div>
        )
    }
}

export default GenericList
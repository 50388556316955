import React, {Component} from 'react'
import {DataTable, ColumnDefinition, CellDefinition} from 'common/components/DataTable';
import Utils from 'common/Utils';
import * as Icons from 'react-bootstrap-icons'
import { Button } from 'react-bootstrap'
import TransferBetweenMonthsWorkflow from 'application/budget/report/TransferBetweenMonthsWorkflow';


var _ = require('lodash');

/** Props
 * - report: the expense report to display. Format: {total: {EUR: 10}, entries: {EUR: [{category: {...}, amount: 10}, ...] }}
 * - expectedExpenses: the expected expense. Format: {total: {EUR: 10}, entries: {EUR: [{category: {...}, amount: 10}, ...] }}
 * - categoryLink: function(category) function to create a link for the category cell
 * - month: YYYY-MM
 * - refreshDataCallback: callback() to refresh data
 */

class ExpenseReportByCategoryTable extends Component {  

    render() {

        const currencies = Object.keys(this.props.report.total)
        return <>
        {currencies.map(curr => {

            return <React.Fragment key={curr}>
                {currencies.length > 1 ? <h2>{curr}</h2> : ''}
                <SingleCurrencyExpenseReport 
                    currency={curr}
                    categories={this.props.report.entries[curr]}
                    total={this.props.report.total[curr]}
                    expectedExpenses={this.props.expectedExpenses.entries[curr]}
                    expectedTotal={this.props.expectedExpenses.total[curr]}
                    categoryLink={this.props.categoryLink}
                    month={this.props.month}
                    refreshDataCallback={this.props.refreshDataCallback}
                />
            </React.Fragment>
        })}
        </>
    }   
}

/**
 * Props:
 * - currency: the currency
 * - categories: the categories entries. Format: [{category: {...}, amount: 10}, ...]
 * - expectedExpenses: the expected expenses by category. Format: [{category: {...}, amount: 10}, ...]
 * - total: the overall total. Format: 10
 * - expectedTotal: the planned total. Format: 10
 * - categoryLink: funcion(category) function to create a link for the category cell
 * - month: YYYY-MM
 * - refreshDataCallback: callback() to refresh data
 */
class SingleCurrencyExpenseReport extends Component {

    constructor() {
        super()
        this.state = {}
        this.hideDialogs = this.hideDialogs.bind(this)
    }

    showMonthTransferDialog(difference) {
        this.setState({
            modal: {
                type: "transfer",
                difference: difference
            }
        })
    }

    trasferDialog(params) {
        const completion = (result) => {
            this.hideDialogs()
            if(result) {
                this.props.refreshDataCallback()
            }
        }
        return <TransferBetweenMonthsWorkflow 
            onSuccess={completion} 
            amount={params.difference}
            month={this.props.month}/>

    }

    hideDialogs() {
        this.setState({modal: false})
    }

    render() {

        const listToMapByCategoryId = (list) => {
            const mapByIdWithArray = _.groupBy(list, c => c.category.id)
            return _.mapValues(mapByIdWithArray, a => a[0].amount)
        }
        const expectedByCategory = listToMapByCategoryId(this.props.expectedExpenses)

        const allExpectedCategories = this.props.expectedExpenses ? this.props.expectedExpenses.map(c => c.category) : []
        const allActualCategories = this.props.categories.map(c => c.category)
        const missingActualCategories = _.differenceBy(allExpectedCategories, allActualCategories, e => e.id)

        const allCategories = this.props.categories.concat(missingActualCategories.map(c => ({amount: "0.00", category: c}) )) 

        const expectedTotal = this.props.expectedTotal || 0
        const difference = this.props.total - expectedTotal
        const transferLink = difference !== 0 ? 
        <Button onClick={() => this.showMonthTransferDialog(difference)} variant="link"><Icons.BoxArrowRight /></Button> 
        : null
        const sumByCurrency = [
            new CellDefinition(`TOTAL`, "td-total"),
            new CellDefinition(<>{Utils.formatMoney(this.props.total, true)} {this.props.currency}</>, "td-currency"),
            new CellDefinition(<>{Utils.formatMoney(expectedTotal, true)} {this.props.currency}</>, "td-currency"),
            new CellDefinition(<>{transferLink}{Utils.formatMoney(difference, true)} {this.props.currency}</>, "td-currency")

        ]

        const columnDefinitions = [
            new ColumnDefinition("Category", obj => this.props.categoryLink(obj.category)),
            new ColumnDefinition("Amount", obj =>  <>{Utils.formatMoney(obj.amount, true)} {this.props.currency}</>, "td-currency", "td-right"),
            new ColumnDefinition("Expected", obj =>  <>{Utils.formatMoney(expectedByCategory[obj.category.id] || 0, true)} {this.props.currency}</>, "td-currency", "td-right"),
            new ColumnDefinition("Difference", obj => <>{Utils.formatMoney(obj.amount - (expectedByCategory[obj.category.id] || 0), true)} {this.props.currency}</>, "td-currency", "td-right")
        ]

        return <>
            {this.state.modal && this.state.modal.type === "transfer" ? this.trasferDialog(this.state.modal) : null}
            <DataTable 
                columns={columnDefinitions} 
                rows={allCategories} 
                summaryRows={[sumByCurrency]}
            />
        </>
    }
}

export default ExpenseReportByCategoryTable
import React, {Component} from 'react'
import OperationWorkflowModal from 'common/components/OperationWorkflowModal'
import API from 'common/logic/API';
import {Button, Col, Form} from 'react-bootstrap'
import {Formik} from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import MonthOfYear from 'common/logic/MonthOfYear'

/** Props
 * - onSuccess: either a callback when successfully saved, 
 *      or a URL to redirect to (string), or null
 */
 class CreatePlanWorkflow extends Component {

    constructor(props) {
        
        super(props)
        this.state = {

        }
    }

    render() {

        const loadingStep = () => {
            return API.get('/budget-plan').then(r => ({plans: r.data.results}))
        }

        const userInputStep = (parameters, loading_result, previousValues, validationErrors, completion_callback) => {
            return {title: "New budget plan", component: this.form(loading_result, previousValues, completion_callback)}
        }

        const executionStep = (parameters, loading_result, user_input)=> {
            if(user_input.copyFrom) {
                return API.post(`/budget-plan/${user_input.month}?copyFrom=${user_input.copyFrom}`)
            } else {
                return API.post(`/budget-plan/${user_input.month}`)
            }
        }

        return <OperationWorkflowModal
            loadingStep={loadingStep}
            userInputStep={userInputStep}
            executionStep={executionStep}
            completionCallback={(result) => this.props.onSuccess(result)}
        />
    }

    form(loading_result, previousValues, completion) {
        const parseForm = (values) => {
            completion({
                month: new MonthOfYear(values.month, values.year).toString(),
                copyFrom: values.compyFrom
            })
        }

        const schema = yup.object().shape({
            month: yup.number().required().integer().min(1).max(12),
            year: yup.number().required().integer().min(1000).max(9999),
            copyFrom: yup.number()
        })

        const plans = loading_result.plans.map((item) => <option value={item.id} key={item.id}>{item.validFrom}</option>);
    
        const initialValues = previousValues ? previousValues : {
            copyFrom: "",
            month: moment().month() + 1,
            year: moment().year()
        }

        return <>
        <Formik
            validationSchema={schema}
            onSubmit={parseForm}
            initialValues={initialValues}
            >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
            }) => (
            <Form noValidate onSubmit={handleSubmit}>
                <Form.Row>
                <Form.Group as={Col} controlId="formYear">
                        <Form.Label>Valid from year</Form.Label>
                        <Form.Control 
                                type='text'
                                name='year' 
                                placeholder="2020" 
                                value={values.year}
                                onChange={handleChange}
                                isInvalid={!!errors.year}
                            />
                            <Form.Control.Feedback type="invalid">{errors.year}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formMonth">
                            <Form.Label>Month</Form.Label>
                            <Form.Control 
                                type='text'
                                name='month' 
                                placeholder="03" 
                                value={values.month}
                                onChange={handleChange}
                                isInvalid={!!errors.month}
                            />
                            <Form.Control.Feedback type="invalid">{errors.month}</Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>

                <Form.Group controlId="formCopy">
                    <Form.Label sm="2">Copy expected movements from</Form.Label>
                    <Form.Control 
                        as='select'
                        name='copyFrom' 
                        value={values.copyFrom}
                        onChange={handleChange}
                        isInvalid={!!errors.copyFrom} 
                    >
                        <option value=""></option>
                        {plans}
                    </Form.Control>
                </Form.Group>
                <Button variant="primary" type="submit">Save</Button>

            </Form>
            )}
        </Formik>
        </>
    }
 }

 export default CreatePlanWorkflow
import {Component} from 'react'
import API from 'common/logic/API'
import { Alert, Card, CardGroup } from 'react-bootstrap'
import BigSpinner from 'common/components/BigSpinner'
import ExpectedMovementsByCategoryTable from 'application/budget/plan/ExpectedMovementsByCategoryTable';
import ExpenseByCategoryChart from 'application/budget/ExpenseByCategoryChart';

var _ = require('lodash');

/**
 * - currentPlan
 */
class MonthlyOutlook extends Component {

    constructor() {
        super()
        this.state = {
            breakdown: null
        }
    }

    componentDidMount() {
        this.refreshData()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentPlan.id !== this.props.currentPlan.id) {
          this.refreshData()
        }
    }

    refreshData() {
        const me = this
        this.setState({breakdown: null, exectedMovements: null})
        API.multiple({
            breakdown: API.get(`/budget-plan/breakdown/${this.props.currentPlan.validFrom}`),
            expectedMovements: API.get(`/expected-movements?month=${this.props.currentPlan.validFrom}`)
        })
        .then(response => {
            me.setState({
                breakdown: response.breakdown,
                expectedMovements: response.expectedMovements.results
            })
        }).catch(error => {
            me.setState({error: error.message})
        })
    }

    render() {
        
        if(this.state.error) {
            return <Alert variant="danger">{this.state.error}</Alert>
        }
        if(!this.state.breakdown) {
            return <BigSpinner></BigSpinner>
        }

        const expectedMovementsByCurrency = _.groupBy(this.state.expectedMovements, e => e.currency.name)
        return <>
            <CardGroup>
                <Card>
                    <ExpectedMovementsByCategoryTable 
                    data={this.state.breakdown} 
                    expectedMovementsByCurrency={expectedMovementsByCurrency} 
                    refreshCallback={() => this.refreshData()}
                    showAddButton={true}
                    currentPlan={this.props.currentPlan}
                    />
                </Card>
                <Card style={{"maxWidth": "15em"}}><ExpenseByCategoryChart data={this.state.breakdown.entries} /></Card>
            </CardGroup>
        </>
    }   
}

export default MonthlyOutlook
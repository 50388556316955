import moment from "moment"

var _ = require('lodash')

class MonthOfYear {

    constructor(month, year) {
        month = parseInt(month)
        if(!_.isInteger(month) || (month < 1 || month > 12)) {
            throw new Error(`Month should be in 1..12 range, ${month}`)
        }
        year = parseInt(year)
        if(!_.isInteger(year) || (year < 1000 || year > 9999)) {
            throw new Error("Year should be in 1000..9999 range")
        }
        this.month = month
        this.year = year
    }

    firstDay() {
        return moment(`${this.year}-${this.month}-01`, "YYYY-MM-DD")
    }

    lastDay() {
        return this.firstDay().endOf("month")
    }

    nextMonth() {
        const endOfYear = this.month === 12
        return new MonthOfYear(endOfYear ? 1 : this.month + 1, endOfYear ? this.year + 1 : this.year)
    }

    previousMonth() {
        const startOfYear = this.month === 1
        return new MonthOfYear(startOfYear ? 12 : this.month - 1, startOfYear ? this.year - 1 : this.year)
    }

    previousYear() {
        return new MonthOfYear(this.month, this.year - 1)
    }

    nextYear() {
        return new MonthOfYear(this.month, this.year + 1)
    }

    static now() {
        return this.fromDate(new Date())
    }

    static fromMoment(moment) {
        return new MonthOfYear(moment.month()+1, moment.year())
    }

    static fromDate(date) {
        return new MonthOfYear(date.getMonth()+1, date.getFullYear())
    }

    /**
     * Parse a string into a MonthOfYear
     * @param {} string 
     * @returns null in case of error 
     */
    static parse(str) {


        const regex = /([0-9]{4})-([0-9]{2})/gm
        const match = regex.exec(str)
        if(!match) {
            return null
        }
        
        const year = parseInt(match[1])
        const month = parseInt(match[2])
        if(month < 1 || month > 12) {
            return null
        }
        if(year < 1000 || year > 9999) {
            return null
        }

        return new MonthOfYear(month, year)
    }

    toString() {
        return `${this.year.toString().padStart(4, '0')}-${this.month.toString().padStart(2, '0')}`
    }

    toMonthYearString() {
        return `${this.month.toString().padStart(2, '0')}-${this.year.toString().padStart(4, '0')}`
    }

    /**
     * Return the min between this and other
     */
    min(other) {
        return this.compare(other) > 0 ? other : this
    }

    /**
     * Return the max between this and other
     */
    max(other) {
        return this.compare(other) > 0 ? this : other
    }

    /**
     * Return 1 if this > other, -1 if other > this, 0 otherwise
     */
    compare(other) {
        if(this.year === other.year) {
            if(this.month === other.month) {
                return 0
            }
            return this.month > other.month ? 1 : -1
        } else {
            return this.year > other.year ? 1 : -1
        }
    }
}

export default MonthOfYear
import React, {Component} from 'react'
import { Button, Card, CardGroup } from 'react-bootstrap';
import Utils from 'common/Utils';
import API from 'common/logic/API';
import BigSpinner from 'common/components/BigSpinner';
import Title from "common/components/Title"
import ExpenseReportByCategoryTable from 'application/budget/report/ExpenseReportByCategoryTable';
import ExpenseByCategoryChart from 'application/budget/ExpenseByCategoryChart';
import * as Icons from 'react-bootstrap-icons'
import MonthOfYear from 'common/logic/MonthOfYear';

class Report extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: null,
            month: this.calculateMonth(),
        }

        this.changeDates = this.changeDates.bind(this)
        this.fetchData = this.fetchData.bind(this)
        this.refreshData = this.refreshData.bind(this)
    }

    componentDidMount() {
        this.fetchData(this.state.month)
    }

    fetchData(month) {
        const me = this
        this.setState({report: null})
        API.multiple({
            "report": API.get(`/expense-report/${month}`),
            "plan": API.get(`/budget-plan/breakdown/${month}`)
        })
        .then(response => {
            me.setState({
                report: response.report,
                plan: response.plan
            })
        }).catch(error => {
            me.setState({error: error.message})
        })
    }

    calculateMonth() {
        const query = new URLSearchParams(window.location.search)
        const fromParam = query.get("month") || ""
        return MonthOfYear.parse(fromParam) || MonthOfYear.now()
    }

    changeDates(month) {
        Utils.pushUrlWithParams({month: month.toString()})
        this.setState({month: month})
        this.fetchData(month)
    }

    refreshData() {
        this.fetchData(this.state.month)
    }

    render() {

        if(!this.state.report) {
            return <BigSpinner />
        }

        const startDate = this.state.month.firstDay()
        const endDate = this.state.month.lastDay()

        const linkToCategory = obj => {    
            return <a href={`/movements?start_date=${startDate.format("YYYY-MM-DD")}&end_date=${endDate.format("YYYY-MM-DD")}&category=${obj.id}`}>{obj.name}</a>
        }

        const nextMonth = this.state.month.nextMonth()
        const prevMonth = this.state.month.previousMonth()

        const prevButton = <Button 
            onClick={() => this.changeDates(prevMonth)}
            variant="link"
            className="prev"><Icons.ChevronDoubleLeft /></Button>

        const nextButton = <Button 
            onClick={() => this.changeDates(nextMonth)}
            variant="link"
            className="next"><Icons.ChevronDoubleRight /></Button>

        const subtitle = <>{prevButton} {this.state.month.toString()} {nextButton}</>

        const title = <Title title="Monthly expense report" 
            subtitle={subtitle}
            />

        return (
            <>
                {title}
                <CardGroup>
                        <Card>
                            <ExpenseReportByCategoryTable
                                month={this.state.month} 
                                report={this.state.report}
                                expectedExpenses={this.state.plan}
                                categoryLink={linkToCategory} 
                                refreshDataCallback={this.refreshData}
                            />
                        </Card>
                        <Card style={{"maxWidth": "15em"}}>
                            <ExpenseByCategoryChart data={this.state.report.entries} />
                        </Card>
                </CardGroup>
            </>
        )
    }
}

export default Report
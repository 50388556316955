import qs from 'qs'
import moment from 'moment';

class Utils {

    static urlWithParameters(url, query, params, keysToDelete =[] ) {
        let searchParams = {...query, ...params}
        keysToDelete.forEach( k => {
            delete searchParams[k]
        });
        const searchString = qs.stringify(searchParams)
        const separator = searchString.length > 0 ? "?" : ""
        return url + separator + searchString
    }

    static currentUrlWithParams(params, keysToDelete = []) {
        let searchParams = qs.parse(window.location.search.substr(1))
        let url = window.location.origin + window.location.pathname
        return  this.urlWithParameters(url, searchParams, params, keysToDelete) + window.location.hash
    }

    static pushUrlWithParams(params, keysToDelete = []) {
        const url = this.currentUrlWithParams(params, keysToDelete)
        if (url !== window.location) {
            window.history.pushState(null, '', url)
        }
    }

    static formatMoney(amount, color = false) {
        const str = parseFloat(amount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})
        if(color) {
            if(amount < 0) {
                return <span className="money-negative">{str}</span>
            } else {
                return <span className="money-positive">{str}</span>
                
            }
        } else {
            return str
        }
    }

    static formatDate(date) {
        return moment(date).format("YYYY-MM-DD")  
    }
}

export default Utils
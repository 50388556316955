import React, {Component} from 'react'
import {ColumnDefinition} from 'common/components/DataTable';
import BigSpinner from 'common/components/BigSpinner';
import {DataTable} from 'common/components/DataTable';
import FetchBehavior from "common/logic/FetchBehavior"
import Utils from 'common/Utils'
import { Button } from 'react-bootstrap';
import API from 'common/logic/API'
import OperationWorkflowModal from 'common/components/OperationWorkflowModal';
import EditExpectedMovementWorkflow from 'application/budget/plan/EditExpectedMovementWorkflow';
import * as Icon from 'react-bootstrap-icons';
import DaysInterval from 'application/budget/DaysInterval';

/**
 * Props
 * - currentPlan
 */
class ExpectedMovementsList extends Component {

    constructor(props) {
        
        super(props)
        this.state = {
            results: null
        }
        this.refreshData = this.refreshData.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentPlan.id !== this.props.currentPlan.id) {
          this.refreshData()
        }
    }

    componentDidMount() {
        this.refreshData()
    }

    refreshData() {
        this.setState({results: null})
        this.fetchBehavior = new FetchBehavior({
            endpoint: `/expected-movements?month=${this.props.currentPlan.validFrom}&limit=1000`, 
            stateHolder: this})
        this.fetchBehavior.fetchFromURL()
    }

    render() {

        return (
            <>
                {this.state.results && this.state.results.error && <p>Error!</p>}
                {!this.state.results || this.state.results.results === null ? <BigSpinner /> : 
                <>
                    <ExpectedMovementsTable 
                        currentPlanId={this.props.currentPlan}
                        results={this.state.results.results} 
                        refreshCallback={() => this.refreshData()} />
                </>}
                
            </>
        )
    }

}

/**
 * Props:
 * - refreshCallback: callback() when data needs to be refreshed
 * - results: data results
 * - currentPlan
 */
export class ExpectedMovementsTable extends Component {

    constructor(props) {
        
        super(props)
        this.state = {}
        this.showCreateDialog = this.showCreateDialog.bind(this)
        this.showDeletionDialog = this.showDeletionDialog.bind(this)
        this.hideDialogs = this.hideDialogs.bind(this)
    }

    refreshData() {
        this.props.refreshCallback()
    }

    columnDefinitions = [
        new ColumnDefinition("Entry", "summary"),
        new ColumnDefinition("Amount", obj =>  <>{Utils.formatMoney(obj.amount, true)} {obj.currency.name}</>, "td-currency", "td-right"),
        new ColumnDefinition("How often", obj =>  new DaysInterval(obj.repeatsAfterDays).inText, "td-size-s"),
        new ColumnDefinition("Monthly amount", obj => <>{Utils.formatMoney(obj.monthlyAmount, true)} {obj.currency.name}</>, "td-currency", "td-right" ),
        new ColumnDefinition("Category", obj => obj.category.name),
        new ColumnDefinition("Guaranteed expense", obj => obj.granted ? "Yes" : "No", "td-size-s"),
        new ColumnDefinition("Actions", obj => { 
            return <> 
            <Button variant="link" title="Edit"
                onClick={this.showEditDialog.bind(this, obj)}><Icon.Pencil /></Button> | 
            <Button variant="link" title="Delete"
                onClick={this.showDeletionDialog.bind(this, obj)} ><Icon.Trash /></Button></> 
        })
        
    ]

    render() {

        let modal = null
        if (this.state.modal) {
            switch(this.state.modal.type) {
                case "delete":
                    modal = this.deletionModal(this.state.modal)
                    break
                case "create":
                case "edit":
                    modal = this.creationOrEditModal(this.state.modal)
                    break;
                default:
            }
        }

        return (
            <>
                <Button variant="link" onClick={this.showCreateDialog}>Add new</Button>

                {modal}                
                {this.resultsUI()}
            </>
        )
    }

    showDeletionDialog(obj) {
        this.setState({modal: 
            {
                type: "delete",
                obj: obj,
            }
        })
    }

    showCreateDialog() {
        this.setState({
            modal: {
                type: "create"
            }
        })
    }

    showEditDialog(obj) {
        this.setState({
            modal: {
                type: "edit",
                obj: obj
            }
        })
    }

    hideDialogs() {
        this.setState({modal: false})
    }

    deletionModal(params) {
        const confirmationStep = (p, l) => { return `Delete expected movement "${params.obj.summary}"?`} 
        const executeStep = (p, l, u) => {return API.delete(`/expected-movements/${params.obj.id}`) }

        const completion = (result) => {
            this.hideDialogs()
            if(result) {
                this.refreshData()
            }
        }

        return <OperationWorkflowModal
            confirmationStep={confirmationStep}
            executionStep={executeStep}
            completionCallback={completion}
        />
    }

    creationOrEditModal(params) {

        const completion = (result) => {
            this.hideDialogs()
            if(result) {
                this.refreshData()
            }
        }
        if(params.obj) {
            <EditExpectedMovementWorkflow 
                currentPlanId={params.obj.budgetPlan.id}
                onSuccess={completion} 
                entryId={params.obj.id}/>
        } else {
            return <EditExpectedMovementWorkflow 
                currentPlanId={this.props.currentPlan.id}
                onSuccess={completion} 
                />
        }
    }

    resultsUI() {

        return <DataTable 
            columns={this.columnDefinitions} 
            rows={this.props.results}
        />
    }
}

export default ExpectedMovementsList
import React, {Component} from 'react'
import OperationWorkflowModal from 'common/components/OperationWorkflowModal'
import API from 'common/logic/API';
import {Button, Col, Form} from 'react-bootstrap'
import Utils from "common/Utils"
import {Formik} from 'formik'
import * as yup from 'yup'
import * as Types from 'common/utils/Types'
import { Redirect } from 'react-router-dom'


/** Props
 * - onSuccess: either a callback when successfully saved, 
 *      or a URL to redirect to (string), or null
 * - static: if false, it's rendered as a floating modal. If true, it's inline.
 * - entryId: if it's a number, it will edit the movement with this ID. If it's null, it will create a new movement
 */
class EditMovementWorkflow extends Component {

    constructor(props) {
        
        super(props)
        this.state = {}
    }

    render() {

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}></Redirect>
        }

        const me = this
        const loadingStep = (parameters) => {
            let dataToFetch = [
                API.get('/funds').then(response => { return response.data["results"]}),
                API.get('/categories').then(response => {return response.data["results"]})
            ]
            if(this.props.entryId !== undefined) {
               dataToFetch.push(API.get(`/movements/${this.props.entryId}`).then(response => {return response.data}))
            }
            return Promise.all(dataToFetch)
            .then((arr) => { 
                return {funds: arr[0], categories: arr[1], movement: arr[2]} 
            })
        }

        const userInputStep = (parameters, loading_result, values, errors, completion) => {
            const form = this.form(loading_result.funds, loading_result.categories, loading_result.movement, completion)
            return {component: form, title: this.props.entryId ? "Edit movement" : "New movement"}
        }
        const executionStep = (parameters, loading_result, user_input) => {
            if (this.props.entryId) {
                return API.put(`/movements/${this.props.entryId}`, user_input)
            } else {
                return API.post("/movements", user_input)
            }
        }
        const completionCallback = (execution_result) => {
            if(Types.isString(me.props.onSuccess)) {
                me.setState({redirect: me.props.onSuccess})
            } else { // is a callback
                me.props.onSuccess(execution_result && execution_result.data)
            }

        }

        return <OperationWorkflowModal
            static={this.props.static}
            loadingStep={loadingStep}
            userInputStep={userInputStep}
            executionStep={executionStep}
            completionCallback={completionCallback}
        />
    }   

    form(loaded_funds, loaded_categories, previousValues, completion) {

        const parseForm = (values) => {
            completion({
                description: values.description,
                statementEntry: values.statementEntry.trim(),
                fundId: values.fund,
                date: values.date,
                amount: values.amount.trim(),
                categoryId: values.category
            })
        }

        const schema = yup.object().shape({
            description: yup.string().trim(),
            amount: yup.number().required(),
            date: yup.date().required(),
            category: yup.number().required(),
            fund: yup.number().required()
        })

        const funds = loaded_funds
            .filter((f) => (f.usedForNewMovements && !f.deleted) || (previousValues && f.id === previousValues.fund.id))

        const fund_options = funds.map((item) => <option value={item.id} key={`fund${item.id}`}>{item.name}</option>);
        const category_options = loaded_categories.map((item) => <option value={item.id} key={`cat${item.id}`}>{item.name}</option>);

        const today = Utils.formatDate(new Date())

        const initialValues = previousValues ? {
            date: previousValues.date,
            description: previousValues.description || "",
            statementEntry: previousValues.statementEntry || "",
            amount: previousValues.amount,
            category: previousValues.category.id,
            fund: previousValues.fund.id
        } : {
            date: today,
            description: "",
            statementEntry: "",
            amount: "",
            category: loaded_categories[0].id,
            fund: funds[0].id,
        }

        return <>
        <Formik
            validationSchema={schema}
            onSubmit={parseForm}
            initialValues={initialValues}
            >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
            }) => (
            <Form noValidate onSubmit={handleSubmit}>
                <Form.Group controlId="formDescription">
                    <Form.Label column sm="2" >Description</Form.Label>
                    <Form.Control
                        type='text'
                        name='description' 
                        placeholder="Description" 
                        value={values.description}
                        onChange={handleChange}
                        isInvalid={!!errors.description}
                        />
                    <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                    <Form.Control
                        type='text'
                        name='statementEntry' 
                        placeholder="Statement entry" 
                        value={values.statementEntry}
                        onChange={handleChange}
                        isInvalid={!!errors.statementEntry}
                        />
                        <Form.Control.Feedback type="invalid">{errors.statementEntry}</Form.Control.Feedback>
                </Form.Group>

                <Form.Row>
                    <Form.Group as={Col} controlId="formAmount">
                        <Form.Label column sm="2">Amount</Form.Label>
                        <Form.Control 
                            type='text'
                            name='amount' 
                            placeholder="-10.00" 
                            value={values.amount}
                            onChange={handleChange}
                            isInvalid={!!errors.amount}
                        />
                        <Form.Control.Feedback type="invalid">{errors.amount}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formDate">
                        <Form.Label column sm="2">Date</Form.Label>
                        <Form.Control 
                            type='text'
                            name='date' 
                            placeholder="2020-12-31" 
                            value={values.date}
                            onChange={handleChange}
                            isInvalid={!!errors.date} 
                        />
                        <Form.Control.Feedback type="invalid">{errors.date}</Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} controlId="formCategory">
                        <Form.Label column sm="2">Category</Form.Label>
                        <Form.Control 
                            as='select'
                            name='category' 
                            value={values.category}
                            onChange={handleChange}
                            isInvalid={!!errors.category} 
                        >
                            {category_options}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">{errors.category}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formFund">
                        <Form.Label column sm="2">Fund</Form.Label>
                        <Form.Control 
                            as='select'
                            name='fund' 
                            value={values.fund}
                            onChange={handleChange}
                            isInvalid={!!errors.fund}
                        >
                            {fund_options}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">{errors.fund}</Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>

                <Button variant="primary" type="submit">Save</Button>

            </Form>
            )}
        </Formik>
        </>
    }
}

export default EditMovementWorkflow
import Config from "config"

const axios = require('axios')
const store = require('store')

class Credentials {
    username;
    password;

    constructor(username, password) {
        this.username = username
        this.password = password
    }
}

const Auth = {

    clearCredentials: () => {
        store.remove('username')
        store.remove('password')
    },

    setCredentials: (credentials) => {
        store.set('username', credentials.username)
        store.set('password', credentials.password)
    },

    getCredentials: () => {
        return new Credentials(store.get('username'), store.get('password'))
    },

    validateCredentials: (credentials) => {
        return axios({
            method: 'get',
            url: Config.apiHost + "/self",
            auth: {
                username: credentials.username,
                password: credentials.password
              },
        }).then(function (response) {
            if (response.status >= 200 && response.status < 300) {
                return true
            } else {
                throw response
            }
        })
    }
}

Object.freeze(Auth)
export {Auth, Credentials}
import React, {Component} from 'react'
import OperationWorkflowModal from 'common/components/OperationWorkflowModal'
import API from 'common/logic/API';
import {Button, Col, Form, Alert, Row} from 'react-bootstrap'
import {Formik} from 'formik'
import * as yup from 'yup'
import * as Types from 'common/utils/Types'
import { Redirect } from 'react-router-dom'


/** Props
 * - onSuccess: either a callback when successfully saved, 
 *      or a URL to redirect to (string), or null
 * - static: if false, it's rendered as a floating modal. If true, it's inline.
 * - variableName: name of the variable to edit
 * - variableType: "string" or "int"
 * - possibleValues: an array of objects [{label: , value: }]
 * - possibleValuesPromise: a promise that returns a list of possible values in the form [{label: , value: }, ...]
 */
class EditVariableWorkflow extends Component {

    constructor(props) {
        
        super(props)
        this.state = {
            isAdding: this.props.variableName === undefined,
        }
    }

    render() {

        if(this.props.variableType !== "int" && this.props.variableType !== "string") {
            return <Alert variant="danger">Programmer's error: unrecognized variable type '{this.props.variableType}'</Alert>
        }

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}></Redirect>
        }

        const me = this
        const loadingStep = (parameters) => {


            const valuePromise = this.props.variableName ?
                API.getVariable(this.props.variableName, this.props.variableType)
                    .then(response => { return {previousValue: response.data["value"]}})
                : Promise.resolve({})

            if(this.props.possibleValuesPromise) {
                return valuePromise.then(
                    valuePromise => { return this.props.possibleValuesPromise.then(
                        possiblePromise => {
                            valuePromise.fetchedPossibleValues = possiblePromise
                            return valuePromise
                        })
                    }
                )
            } else {
                return valuePromise
            }
        }

        const userInputStep = (parameters, loading_result, values, errors, completion) => {
            const form = this.form(parameters, loading_result.previousValue, loading_result.fetchedPossibleValues || this.props.possibleValues, completion)
            const nameTitle = this.state.isAdding ? <em>{this.props.variableName}</em> : null
            return {component: form, title: `${this.state.isAdding ? "Add" : "Edit"} ${this.props.variableType} variable ${nameTitle}`}
        }
        const executionStep = (parameters, loading_result, user_input) => {
            const value = this.props.variableType === "int" ? parseInt(user_input.value) : user_input.value
            return API.setVariable(user_input.name, value, this.props.variableType)
        }
        const completionCallback = (execution_result) => {
            if(Types.isString(me.props.onSuccess)) {
                me.setState({redirect: me.props.onSuccess})
            } else { // is a callback
                me.props.onSuccess(execution_result && execution_result.data)
            }
        }

        return <OperationWorkflowModal
            static={this.props.static}
            loadingStep={loadingStep}
            userInputStep={userInputStep}
            executionStep={executionStep}
            completionCallback={completionCallback}
        />
    }   

    form(parameters, previousValue, possibleValues, completion) {

        const schema = yup.object().shape({
            value: this.props.variableType === "int" ? yup.number().required() : yup.string().required(),
            name: yup.string().required()
        })

        const initialValues = {
            value: previousValue || "",
            name: this.props.variableName || ""
        }
        
        return <>
        <Formik
            validationSchema={schema}
            onSubmit={completion}
            initialValues={initialValues}
            >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
            }) => (
            <Form noValidate onSubmit={handleSubmit}>
                
                <Form.Group as={Row} className="mb-3" controlId="formName">
                    <Form.Label column sm="2">Name</Form.Label>
                    <Col sm="10">
                        <Form.Control 
                            type='text' 
                            name='name'
                            placeholder="Name" 
                            value={values.name}
                            onChange={handleChange}
                            isInvalid={!!errors.name} 
                            readOnly={!this.state.isAdding}
                            />
                        <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formValue">
                    <Form.Label column sm="2">Value</Form.Label>
                    <Col sm="10">
                        {possibleValues ?
                            <Form.Control 
                                as='select'
                                name='value'
                                value={values.value} 
                                onChange={handleChange}
                                isInvalid={!!errors.value}
                            >
                            {values.value ? " " : <option></option>}
                            {
                                possibleValues.map(obj => { return <option value={obj.value} key={`value_${obj.value}`}>{obj.label}</option>})
                            }
                            </Form.Control>
                        :
                            <Form.Control 
                                type='text'
                                placeholder='Value' 
                                name='value'
                                value={values.value} 
                                onChange={handleChange}
                                isInvalid={!!errors.value}
                            />
                        }
                        
                        <Form.Control.Feedback type="invalid">{errors.value}</Form.Control.Feedback>
                    </Col>
                </Form.Group>

                <Button variant="primary" type="submit">Save</Button>

            </Form>
            )}
        </Formik>
        </>
    }
}

export default EditVariableWorkflow
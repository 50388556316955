import React, {Component} from 'react'
import Spinner from "react-bootstrap/Spinner"
import Alert from "react-bootstrap/Alert"

class BigSpinner extends Component {

    render() {
        return (<Alert variant="secondary" className="loading-alert">
            <Spinner
                animation="border"
                role="status"
                aria-hidden="true"/>
        </Alert>)
        
    }

}

export default BigSpinner
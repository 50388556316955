import React, {Component} from 'react'
import OperationWorkflowModal from 'common/components/OperationWorkflowModal'
import API from 'common/logic/API';
import {Button, Form} from 'react-bootstrap'
import {Formik} from 'formik'
import * as yup from 'yup'
import * as Types from 'common/utils/Types'
import { Redirect } from 'react-router-dom'
import MonthOfYear from 'common/logic/MonthOfYear';

const TransferCategoryVariable = "month-transfer-last-category"
const TransferFundVariable = "month-transfer-last-fund"

/** Props
 * - onSuccess: either a callback when successfully saved, 
 *      or a URL to redirect to (string), or null
 * - static: if false, it's rendered as a floating modal. If true, it's inline.
 * - month: YYYY-MM
 * - amount: 1000
 */
class TransferBetweenMonthsWorkflow extends Component {

    constructor(props) {
        
        super(props)
        this.state = {}
    }

    render() {

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}></Redirect>
        }

        const me = this
        const loadingStep = (parameters) => {
            let dataToFetch = {
                "funds": API.get('/funds'),
                "categories": API.get('/categories'),
                "lastCategory": API.getVariable(TransferCategoryVariable, "int"),
                "lastFund": API.getVariable(TransferFundVariable, "int")
            }
            return API.multiple(dataToFetch)
            .then((r) => { 
                const data = {
                    lastFund: r.lastFund.value,
                    lastCategory: r.lastCategory.value, 
                    funds: r.funds.results, 
                    categories: r.categories.results} 
                return data
            })
        }

        const userInputStep = (parameters, loading_result, values, errors, completion) => {
            const form = this.form(loading_result, completion)
            return {component: form, title: "Transfer offset to another month"}
        }
        const executionStep = (parameters, loading_result, user_input) => {
            const requests = [
                API.post("/movements/month-transfer", user_input),
                API.setVariable(TransferCategoryVariable, user_input.categoryId),
                API.setVariable(TransferFundVariable, user_input.fundId)
            ]
            return API.multiple(requests)
        }
        const completionCallback = (execution_result) => {
            if(Types.isString(me.props.onSuccess)) {
                me.setState({redirect: me.props.onSuccess})
            } else { // is a callback
                me.props.onSuccess(execution_result)
            }

        }

        return <OperationWorkflowModal
            static={this.props.static}
            loadingStep={loadingStep}
            userInputStep={userInputStep}
            executionStep={executionStep}
            completionCallback={completionCallback}
        />
    }   

    form(loading_results, completion) {

        const loaded_funds = loading_results.funds
        const loaded_categories = loading_results.categories

        const toMonth = this.props.month.nextMonth()

        const parseForm = (values) => {

            let data = {
                reason: `Transfer from ${this.props.month.toString()} to ${values.toMonth}`,
                fundId: values.fund,
                fromMonth: this.props.month.toString(),
                toMonth: values.toMonth,
                amount: values.amount,
                categoryId: values.category
            }
            completion(data)
        }

        const schema = yup.object().shape({
            amount: yup.number().required(),
            toMonth: yup.string().required().matches(MonthOfYear.regex),
            category: yup.number().required(),
            fund: yup.number().required()
        })

        const funds = loaded_funds
            .filter((f) => f.usedForNewMovements && !f.deleted)

        const fund_options = funds.map((item) => <option value={item.id} key={`fund${item.id}`}>{item.name}</option>);
        const category_options = loaded_categories.map((item) => <option value={item.id} key={`cat${item.id}`}>{item.name}</option>);
                
        const initialValues =  {
            toMonth: toMonth.toString(),
            amount: this.props.amount.toFixed(2),
            category: (loading_results.lastCategory !== undefined) ? loading_results.lastCategory : loaded_categories[0].id,
            fund: (loading_results.lastFund !== undefined) ? loading_results.lastFund : funds[0].id,
        }

        return <>
        <Formik
            validationSchema={schema}
            onSubmit={parseForm}
            initialValues={initialValues}
            >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
            }) => (
            <Form noValidate onSubmit={handleSubmit}>
                
                    <Form.Group controlId="formAmount">
                        <Form.Label>Amount</Form.Label>
                        <Form.Control 
                            type='text'
                            name='amount' 
                            placeholder="-10.00" 
                            value={values.amount}
                            onChange={handleChange}
                            isInvalid={!!errors.amount}
                        />
                        <Form.Control.Feedback type="invalid">{errors.amount}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group  controlId="formDate">
                        <Form.Label>To month</Form.Label>
                        <Form.Control 
                            type='text'
                            name='toMonth' 
                            placeholder="2020-12-31" 
                            value={values.toMonth}
                            onChange={handleChange}
                            isInvalid={!!errors.toMonth} 
                        />
                        <Form.Control.Feedback type="invalid">{errors.toMonth}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formCategory">
                        <Form.Label>Category</Form.Label>
                        <Form.Control 
                            as='select'
                            name='category' 
                            value={values.category}
                            onChange={handleChange}
                            isInvalid={!!errors.category} 
                        >
                            {category_options}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">{errors.category}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formFund">
                        <Form.Label>Fund</Form.Label>
                        <Form.Control 
                            as='select'
                            name='fund' 
                            value={values.fund}
                            onChange={handleChange}
                            isInvalid={!!errors.fund}
                        >
                            {fund_options}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">{errors.fund}</Form.Control.Feedback>
                    </Form.Group>

                <Button variant="primary" type="submit">Save</Button>

            </Form>
            )}
        </Formik>
        </>
    }
}

export default TransferBetweenMonthsWorkflow
import { Pagination } from 'react-bootstrap';
import React, {Component} from 'react'

/**
 * Props:
 * - start
 * - limit
 * - sort
 * - hasMore
 * - fetchCallback
 */
class PaginationBox extends Component {

    render() {
        
        const maxPages = 6
        const callback = this.props.fetchCallback
        const currentPage = Math.ceil(this.props.start / this.props.limit)+1
        const allPages = [...Array(currentPage).keys()].map(i => {
            if (currentPage > maxPages && i < currentPage - maxPages) {
                return null
            }
            const startNext = i*this.props.limit
            const isActive = (i+1) === currentPage
            return <Pagination.Item active={isActive} key={i+1} onClick={() => callback(startNext, this.props.sort)}>{i+1}</Pagination.Item>
        })

        const startNext = this.props.start + this.props.limit
        const startBefore = this.props.limit * (currentPage - maxPages - 1)
        return (
            <div>
            <Pagination size="sm">
                {currentPage > 2 ? <Pagination.First onClick={() => callback(0, this.props.sort)}/> : null}
                {currentPage > maxPages ? <Pagination.Ellipsis onClick={() => callback(startBefore, this.props.sort)}/> : null}
                {allPages}
                {this.props.hasMore ? <Pagination.Ellipsis onClick={() => callback(startNext, this.props.sort)}/> : null}
                {this.props.hasMore ? <Pagination.Next onClick={() => callback(startNext, this.props.sort)}/> : null}
            </Pagination>
        </div>
        )
    }
}

export default PaginationBox
import React, {Component} from 'react'
import { Modal } from 'react-bootstrap'
import Calendar from 'react-calendar'
import MonthOfYear from 'common/logic/MonthOfYear'
import 'react-calendar/dist/Calendar.css';

/**
 * Props:
 * - onSelect: callback(MonthOfYear)
 * - onClose: callback()
 * - show: whether it should already be open
 * - selectRange: whether it should select a range rather than a single month
 */
class MonthPicker extends Component {

    render() {
        return <> 
        <Modal show={this.props.show} onHide={this.props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Pick a month</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Calendar
                    maxDetail="year"
                    isOpen={true}
                    selectRange={!!this.props.selectRange}
                    onChange={(e) => this.onChange(e)}
                />
            </Modal.Body>
        </Modal>
        </>
    }

    onChange(value) {
        if(this.props.selectRange) {
            this.props.onSelect(value.map(d => MonthOfYear.fromDate(d)))
        } else {
            this.props.onSelect(MonthOfYear(value))
        }
    }

}

export default MonthPicker
import React, {Component} from 'react'
import {ColumnDefinition} from 'common/components/DataTable';
import GenericList from 'common/components/GenericList';
import FilterBox from "common/components/FilterBox"
import Utils from "common/Utils.js"
import {Link, Redirect} from 'react-router-dom'
import {Button} from 'react-bootstrap'
import EditMovementWorkflow from 'application/movements/EditMovementWorkflow'
import * as Icon from 'react-bootstrap-icons';
import API from 'common/logic/API'
import OperationWorkflowModal from 'common/components/OperationWorkflowModal';
import { withRouter } from 'react-router-dom';



/** Props:
 * - url: base url for the page
 * - query: the url query
 */
class MovementsList extends Component {

    expectedFilters = [
        "start_date",
        "end_date",
        "category",
        "fund"
    ]

    constructor(props) {
        super(props)
        this.state = {
            modal: false,
            dataKey: Date.now(),
            query: this.props.query
        }
        this.onUpdateFilters = this.onUpdateFilters.bind(this)
        this.showCreateDialog = this.showCreateDialog.bind(this)
        this.showDeletionDialog = this.showDeletionDialog.bind(this)
        this.hideDialogs = this.hideDialogs.bind(this)
    
    }

    onUpdateFilters(filters) {
        let prevQuery = {...this.state.query}
        this.expectedFilters.forEach((f) => delete prevQuery[f]) // might have been cleared from filters
        let newQuery = {...prevQuery, ...filters}
        this.props.history.push(Utils.urlWithParameters(this.props.url, newQuery));
        this.setState({query: newQuery})
    }


    showDeletionDialog(id, name) {
        this.setState({modal: 
            {
                type: "delete",
                id: id,
                name: name,
            }
        })
    }

    showCreateDialog() {
        this.setState({
            modal: {
                type: "create"
            }
        })
    }

    showEditDialog(id) {
        this.setState({
            modal: {
                type: "edit",
                id: id
            }
        })
    }

    hideDialogs() {
        this.setState({modal: false})
    }

    refreshData() {
        this.setState({dataKey: Date.now()})
    }

    deletionModal(params) {
        const confirmationStep = (p, l) => { return `Delete movement "${params.name}"?`} 
        const executeStep = (p, l, u) => {return API.delete(`/movements/${params.id}`) }

        const completion = (result) => {
            this.hideDialogs()
            if(result) {
                this.refreshData()
            }
        }

        return <OperationWorkflowModal
            confirmationStep={confirmationStep}
            executionStep={executeStep}
            completionCallback={completion}
        />
    }

    creationOrEditModal(params) {

        const completion = (result) => {
            this.hideDialogs()
            if(result) {
                this.setState({dataKey: Date.now()})
            }
        }
        return <EditMovementWorkflow onSuccess={completion} entryId={params.id}/>
    }

    render() {

        if(this.state.redirect) {
            return <Redirect push to={this.state.redirect} />
        }

        let modal = null
        switch(this.state.modal.type) {
            case "delete": 
                modal = this.deletionModal(this.state.modal);
                break;
            case "create":
            case "edit":
                modal = this.creationOrEditModal(this.state.modal);
                break;
            default:
        }

        let query = this.state.query
        let searchParams = {}
        this.expectedFilters.forEach((f) => {
            searchParams[f] = query[f]
        })
        
        const linkToCategory = obj => {
            const url = Utils.urlWithParameters(this.props.url, this.state.query, {category: obj.category.id})
            return <Link to={url}>{obj.category.name}</Link>
        }
        const linkToFund = obj => {
            const url = Utils.urlWithParameters(this.props.url, this.state.query, {fund: obj.fund.id})
            return <Link to={url}>{obj.fund.name}</Link>
        }

        const definitions = [
            new ColumnDefinition("Date", "date"),
            new ColumnDefinition("Summary", obj => {
                const space = obj.description && obj.statementEntry ? <br/> : ''
                return <><strong>{obj.description}</strong>{space}{obj.statementEntry}</> 
                }, "td-maxquarter"),
            new ColumnDefinition("Amount", obj => <>{Utils.formatMoney(obj.amount, true)} {obj.fund.currency.name}</>, "td-currency", "td-right"),
            new ColumnDefinition("Category", linkToCategory),
            new ColumnDefinition("Fund", linkToFund),
            new ColumnDefinition("Actions", obj => { 
                return <>
                <Button variant="link" title="Edit"
                    onClick={this.showEditDialog.bind(this, obj.id)}><Icon.Pencil /></Button> | 
                <Button variant="link" title="Delete"
                    onClick={this.showDeletionDialog.bind(this, obj.id, obj.statementEntry || obj.description)}><Icon.Trash /></Button></> 
            })
        ]

        const filterBox = <>
            <FilterBox 
            filters={searchParams} 
            onUpdateFilters={this.onUpdateFilters} />
        </>

        return (
            <>{modal}
            <GenericList 
            title="Movements" 
            subtitle={<>
            <Button variant="link" onClick={this.showCreateDialog}>Add new</Button>/
            <Button variant="link" onClick={() => this.setState({"redirect": "/movements/import"})}>Import from bank statement</Button>
            </>}
            columns={definitions} 
            endpoint={this.props.url} 
            endpointParams={searchParams}
            beforeTable={filterBox}
            dataKey={this.state.dataKey}
            />
            </>
        )
    }
}

export default withRouter(MovementsList)
import React, {Component} from 'react'
import {Button, Form, Alert, Row, Col } from 'react-bootstrap'
import BigSpinner from 'common/components/BigSpinner'
import {Formik} from 'formik'
import { DataTable, ColumnDefinition } from 'common/components/DataTable'
import EditVariableWorkflow from 'application/variables/EditVariableWorkflow'
import * as yup from 'yup'  
import * as Icons from 'react-bootstrap-icons'
import Utils from 'common/Utils'
import API from 'common/logic/API'
import { Redirect, Link } from 'react-router-dom'


const ProcessState = Object.freeze({
    "LOADING":1,
    "WAITING_FILE":2, 
    "UPLOADING":3,
    "WAITING_LIST_REVIEW": 4, 
    "EXECUTING":5, 
    "DONE": 6,
    "ERROR": 7
})

const defaultImportCategoryVariable = "default-import-category"

class ImportStatement extends Component {

    constructor(props) {
        super(props)
        this.state = {
            step: ProcessState.LOADING
        }
        this.showSetCategoryDialog = this.showSetCategoryDialog.bind(this)
        this.hideDialogs = this.hideDialogs.bind(this)
    }

    componentDidMount() {
        const me = this
        API.multiple({
            funds: API.get('funds'),
            defaultCategory: API.get(`variables/int/${defaultImportCategoryVariable}`)
        })
        
        .then(response => {
            me.setState({
                funds: response.funds.results,
                defaultCategory: response.defaultCategory.value,
                step: ProcessState.WAITING_FILE,
            })
        }).catch(reason => {
            me.setState({
                step: ProcessState.ERROR,
                error: reason.message
            })
        })
    }

    render() {

        let modal = null
        if (this.state.modal) {
            switch(this.state.modal.type) {
                case "setCategory": 
                    modal = this.setCategoryModal();
                    break;
                default:
            }
        }

        return <>
        <h1>Import statement</h1>
        {modal}
        {this.state.error ? <Alert variant="danger">{this.state.error}</Alert> : null}
        
        {this.renderBody()}
        </>
    }

    renderBody() {

        const categoryWarning = this.state.defaultCategory === undefined ? <Alert variant="warning">The default import category is not set{' '} 
            <Button title="Set" size="sm" onClick={this.showSetCategoryDialog}>
                Set
            </Button>
        </Alert> : null

        switch(this.state.step) {
            case ProcessState.LOADING:
                return <BigSpinner />
            case ProcessState.WAITING_FILE:
                return <>
                    {categoryWarning}
                    {this.fileForm()}
                </>
            case ProcessState.UPLOADING:
                return <BigSpinner />
            case ProcessState.WAITING_LIST_REVIEW:
                return <>
                    {categoryWarning}
                    {this.movementsForm()}
                </>
            case ProcessState.ERROR:
                return <></>
            case ProcessState.DONE:
                return <Redirect to="/movements" />
            default:
                return <></>
        }
    }
    
    movementsForm() {
        if (!this.state.parsed || !this.state.parsed.movements || this.state.parsed.movements.length === 0) {
            return <Alert variant="warning">No new movements parsed</Alert> 
        }
        
        const me = this
        const parseForm = (values) => {
            const movements = me.state.parsed.movements
            me.setState({step: ProcessState.EXECUTING}, () => {
                const payload = movements.map((movement, i) => {
                    return {
                        description: movement.description,
                        statementEntry: movement.statementEntry,
                        fundId: me.state.parsed.fund.id,
                        date: movement.date,
                        amount: movement.amount,
                        categoryId: values.category[i]
                    }
                })
                .filter((m, i) => values.import[i])
                API.post("/movements/batch", payload)
                    .then(r => {
                        me.setState({step: ProcessState.DONE})
                    })
                    .catch(e => {
                        me.setState({
                            step: ProcessState.ERROR,
                            error: e.message
                        })
                    })
            })
        }


        const schema = yup.object().shape({
            import: yup.array(),
            category: yup.array()
        })

        const categories_options = this.state.categories.map((item) => <option value={item.id} key={`cat_${item.id}`}>
            {item.name}
            </option>)

        const definitions = (handleChange, values) => {
            return [
            new ColumnDefinition("Import", (obj, i) => {
                return <Form.Check 
                    type="checkbox" 
                    label="" 
                    name={`import[${i}]`}
                    checked={values.import[i]}
                    onChange={handleChange}
                    />
            }),
            new ColumnDefinition("Date", "date"),
            new ColumnDefinition("Description", "description"),
            new ColumnDefinition("Statement Entry", "statementEntry"),
            new ColumnDefinition("Amount", obj => Utils.formatMoney(obj.amount, true), "td-currency", "td-right"),
            new ColumnDefinition("Category", (obj, i) => { 
                return <Form.Control
                        as='select'
                        name={`category[${i}]`} 
                        onChange={handleChange}
                        value={values.category[i]}
                    >
                        {categories_options}
                    </Form.Control>
            })
        ]}

        const initialValues = {
            import: this.state.parsed.movements.map((obj) => true),
            category: this.state.parsed.movements.map((obj) => { return obj.suggestedCategoryId || 
                this.state.defaultCategory ||
                this.state.categories[0].id }),
        }

        return <>
            <Alert variant="success">Detected institution <strong>{this.state.parsed.institution}</strong> for fund <strong>{this.state.parsed.fund.name}</strong></Alert>
            You can add more classification rules in <a href="/classification-rules" target="_blank">another tab</a> and then {' '}
            <button className="linkbutton" onClick={() => this.uploadAndParseFileForm(this.state.previouslySubmittedFileForm)}>
                <Icons.ArrowClockwise /> Re-evaluate the statement
                </button>
            <Formik
            validationSchema={schema}
            onSubmit={parseForm}
            initialValues={initialValues}
            >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
            }) => (


            <Form noValidate onSubmit={handleSubmit}>
                <DataTable 
                    columns={definitions(handleChange, values)}
                    rows={this.state.parsed.movements}
                />
                <Button variant="primary" type="submit">Insert</Button>
            </Form>
            )}
        </Formik>
        </>
    }

    uploadAndParseFileForm(values) {
        const me = this
        this.setState({error: null, previouslySubmittedFileForm: values})
        const fund = values.fund ? values.fund : ""
        me.setState({step: ProcessState.UPLOADING}, () => {
            API.multiple({
                parsed: API.postFile(`/movements/statement?fund=${fund}`, values.file),
                categories: API.get('categories')
            })
            .then(response => {
                me.setState({
                    step: ProcessState.WAITING_LIST_REVIEW,
                    categories: response.categories.results,
                    parsed: response.parsed,
                })
            }).catch(e => {
                if(e.response.data.label === "missing-parameter") {
                    me.setState({
                        step: ProcessState.WAITING_FILE,
                        error: "Please specify the fund"
                    })
                } else if(e.response.data.label === "no-fund-with-institution") {
                    const institution = e.response.data.stringFields.institution
                    me.setState({
                        step: ProcessState.WAITING_FILE,
                        error: <>No fund is associated with institution <strong>{institution}</strong>. Please select a fund or <Link to="/funds">associate the institution with a fund</Link>.</>
                    })
                } else {
                    me.setState({
                        step: ProcessState.ERROR,
                        error: e.message
                    })    
                }
            })
        })
    }

    fileForm() {

        const me = this
        const parseForm = (values) => {
            me.uploadAndParseFileForm(values)
        }

        const schema = yup.object().shape({
            file: yup.mixed().required(),
            fund: yup.number(),
        })

        const funds = this.state.funds
            .filter((f) => (f.usedForNewMovements && !f.deleted))
        const fund_options = funds.map((item) => <option value={item.id} key={`funds_${item.id}`}>{item.name}</option>)


        return <>
        <Formik
            onSubmit={parseForm}
            initialValues={{}}
            validationSchema={schema}
            >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
            }) => (
            <Form noValidate onSubmit={handleSubmit}>
                <Form.Group as={Row} controlId="fileControl" className="mb-3">
                <Form.Label column sm="2">Statement</Form.Label>
                <Col>
                    <Form.Control type="file" size="sm" name="file"
                        onChange={(event) => {
                            values.file = event.currentTarget.files[0];
                          }}
                    />
                </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formFund">
                    <Form.Label column sm="2">Fund</Form.Label>
                    <Col>
                    <Form.Control 
                        as='select'
                        name='fund' 
                        value={values.fund}
                        onChange={handleChange}
                    >
                        <option>[Auto]</option>
                        {fund_options}
                    </Form.Control>
                    </Col>
                </Form.Group>
                <Button variant="primary" type="submit">Upload</Button>
            </Form>
            )}
        </Formik>
        </>
    }

    showSetCategoryDialog() {
        this.setState({
            modal: {
                type: "setCategory"
            }
        })
    }

    setCategoryModal() {
        const completion = (result) => {
            this.hideDialogs()
            if(result) {
                this.setState({
                    defaultCategory: result.value
                })
            }
        }

        const valuesPromise = API.get('categories').then(
            response => { 
                return response.data.results.map(obj => {return {label: obj.name, value: obj.id}})
            }
        )

        return <EditVariableWorkflow 
            onSuccess={completion} 
            variableName={defaultImportCategoryVariable} 
            possibleValuesPromise={valuesPromise}
            variableType="int" />
    }

    hideDialogs() {
        this.setState({modal: false})
    }
}

export default ImportStatement
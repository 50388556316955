import React, {Component} from 'react'
import Table from "react-bootstrap/Table"

/**
 * Props:
 * - colums: an array of ColumDefinition
 * - rows: an array of rows, whose value displayed will depend on the ColumnDefinition
 * - summaryRows: rows to add at the end of the table
 * - onRowClick: callback(index, obj) when a row is clicked
 */
class DataTable extends Component {

    render() {
        const columns = this.props.columns
        const rows = this.props.rows
        const body = rows.map((obj, i1) => {
            const onRowClick = () => {
                if(this.props.onRowClick) this.props.onRowClick(i1, obj)
            }
            
            const cells = columns.map((column, i2) => {
                return <td key={i2} className={column.classNames}>{column.extractValue(obj, i1)}</td>
            })
            return ( <tr key={i1} onClick={onRowClick}>
                {cells}
            </tr> )
        })

        const head = columns.map((column, index) => {
            return (<th key={index} className={column.headerClassNames}>{column.label}</th>)
        })

        
        const summary = this.props.summaryRows && this.props.summaryRows.map((row, i) => {
            return <tr className="summary-row" key={`summary-${i}`}>
            {row.map((cell, i) => {
                let value = null
                let classNames = null
                if (cell instanceof CellDefinition) {
                    value = cell.value
                    classNames = cell.classNames
                } else {
                    value = cell
                }
                return <td key={i} className={classNames}>{value}</td>
            })}
            </tr>
        })

        return (
            <Table striped hover size="sm">
                <thead>
                    <tr>
                        {head}
                    </tr>
                </thead>
                <tbody>
                    {body}
                    {summary}
                </tbody>
            </Table>
        )
    }
}

/**
 * Parameters
 * - label: the text to display in the table header
 * - valueExtraction: value to display in the cell. It can be null (will use the label as an index in the object), 
 *  or a string (will use the string as an index in the object) or a function (will call `function(obj)`)
 */
class ColumnDefinition {

    constructor(label, 
        valueExtraction = null, 
        classNames = null,
        headerClassNames = null
        ) {
        this.label = label
        this.valueExtraction = valueExtraction
        this.classNames = classNames
        this.headerClassNames = headerClassNames
    }

    extractValue(object, index) {
        if (this.valueExtraction == null) {
            return object[this.label]
        } else if (typeof this.valueExtraction === 'string' || this.valueExtraction instanceof String) {
            return object[this.valueExtraction]
        } else {
            return this.valueExtraction(object, index)
        }
    }
}

class CellDefinition {

    constructor(value,
        classNames = null) 
    {
        this.value = value
        this.classNames = classNames 
    }
}

export {DataTable, ColumnDefinition, CellDefinition}